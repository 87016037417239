<template>
    <div>
		<el-table stripe v-loading="loading" :data="dataList">
			<el-table-column label="id" prop="id" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="上游渠道" prop="channelName" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="代理商编号" prop="oneAgentNo" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="消息类型" prop="dataType" min-width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span>{{dicts.CHANNEL_DATA_PUSH_TYPE[scope.row.dataType]}}</span>
				</template>
			</el-table-column>
			<el-table-column label="上游订单号" prop="outOrderNo" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column label="上游商户号" prop="outMerchantNo" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column label="SN" prop="sn" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column label="状态" prop="status" min-width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<el-button :type="scope.row.status=='INIT'?'primary':scope.row.status=='FAIL'?'danger':'success'" 
                    plain size="mini" class="button-status" v-if="scope.row.status">{{dicts.CHANNEL_PUSH_STATUS[scope.row.status]}}</el-button>
				</template>
			</el-table-column>
			<el-table-column label="推送时间" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column label="异常消息" prop="errorMsg" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="120" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="text" @click="handlePush(scope.row)">
						<i class="el-icon-refresh-right"></i>
						重推
					</el-button>
				</template>
			</el-table-column>
		</el-table>

		<Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
	</div>
</template>

<script>
	import {newsApi} from '@/api';
	export default {
		name: "ToolBar",
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			queryParams: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				pageNo: 1,
				pageSize: 10,
				loading: false,
				total: 0,
				dataList: [],
				selectRow: {},
				showCustomForm: false,
				isAdd: false,
				isLook: true
			}
		},
		mounted() {
		},
		watch: {
			queryParams: function () {
				this.pageNo = 1;
				this.getList();
			},
		},
		methods: {
			async getList() {
				this.loading = true;
				let result = await newsApi.channelPushData.pageList(this.pageNo, this.pageSize, this.queryParams);
				this.loading = false;
				this.total = result.count || 0;
				this.dataList = result.data || [];
			},
			async handlePush(row) {
				this.$confirm("确定要重推吗?",
					"警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(async () => {
					let result = await newsApi.channelPushData.rePush(row.id);
					if (result.success) {
						this.Message.success("重推成功");

						this.getList();
					}
				});
			},
			onClose(){
				this.showCustomForm = false;
				this.getList();
			}
		}
	};
</script>

<style scoped>

</style>
